import mnml from '@dryan-llc/mnml.js';
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  LinearScale,
  LineController,
  LineElement,
  PieController,
  PointElement,
  SubTitle,
  Title,
  Tooltip,
} from 'chart.js';

Chart.register(
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  PieController,
  PointElement,
  SubTitle,
  Tooltip,
  Title
);

mnml.listen('click', 'dialog', (ev, target) => {
  const event = ev as MouseEvent;
  const dialog = target as HTMLDialogElement;
  const rect = dialog.getBoundingClientRect();
  if (
    !(
      rect.top <= event.clientY &&
      event.clientY <= rect.top + rect.height &&
      rect.left <= event.clientX &&
      event.clientX <= rect.left + rect.width
    )
  ) {
    dialog.close();
  }
});

type CSVDataEntry = (string | number)[];
type CSVDataEntries = CSVDataEntry[];

class ChartWithCSVData extends Chart {
  csvData: CSVDataEntries | string;
}

const _elemCache: { [key: string]: HTMLElement } = {};

const elem = (tag: string): HTMLElement => {
  if (!_elemCache[tag]) {
    _elemCache[tag] = document.createElement(tag);
  }
  return _elemCache[tag].cloneNode(false) as HTMLElement;
};

const showLoader = () => {
  document.documentElement.classList.add('fetching-data');
};

const hideLoader = () => {
  document.documentElement.classList.remove('fetching-data');
};

const formatters = {
  money: (value: number, digits: 0 | 2 = 2): string => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: digits,
    }).format(value);
  },
  date: (
    value: Date,
    options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }
  ): string => {
    return new Intl.DateTimeFormat('en-US', options).format(value);
  },
};

const charts: { [key: string]: Chart } = {};

Chart.defaults.font.family = `system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`;

const getIsDarkMode = () => {
  return (
    window.matchMedia('(prefers-color-scheme: dark)').matches &&
    !document.documentElement.dataset.theme?.includes('light')
  );
};
let darkMode = getIsDarkMode();
let gridLinesColor = darkMode
  ? 'rgba(255, 255, 255, 0.15)'
  : 'rgba(0, 0, 0, 0.25)';
Chart.defaults.color = darkMode ? 'white' : 'black';
const updateChartColors = () => {
  gridLinesColor = darkMode
    ? 'rgba(255, 255, 255, 0.25)'
    : 'rgba(0, 0, 0, 0.25)';
  Chart.defaults.color = darkMode ? 'white' : 'black';
  Object.values(charts).map((chart) => {
    if (chart.config.options?.scales) {
      if (chart.config.options.scales.y) {
        if (chart.config.options.scales.y.grid) {
          chart.config.options.scales.y.grid.color = gridLinesColor;
        }
        if (chart.config.options.scales.y.ticks) {
          chart.config.options.scales.y.ticks.color = darkMode
            ? 'white'
            : 'black';
        }
      }
      if (chart.config.options.scales.x) {
        if (chart.config.options.scales.x.grid) {
          chart.config.options.scales.x.grid.color = gridLinesColor;
        }
        if (chart.config.options.scales.x.ticks) {
          chart.config.options.scales.x.ticks.color = darkMode
            ? 'white'
            : 'black';
        }
      }
    }
    chart.update();
  });
};
window
  .matchMedia('(prefers-color-scheme: dark)')
  .addEventListener('change', (e) => {
    darkMode = getIsDarkMode();
    updateChartColors();
  });
new MutationObserver((mutations) => {
  mutations.map((mutation) => {
    if (mutation.type === 'attributes') {
      if (mutation.attributeName === 'data-theme') {
        darkMode = getIsDarkMode();
        updateChartColors();
      }
    }
  });
}).observe(document.documentElement, { attributes: true });

const downloadIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4M7 10l5 5 5-5M12 15V3"/></svg>`;
const sheetsIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M280 80C266.7 80 256 69.25 256 56C256 42.75 266.7 32 280 32H424C437.3 32 448 42.75 448 56V200C448 213.3 437.3 224 424 224C410.7 224 400 213.3 400 200V113.9L200.1 312.1C191.6 322.3 176.4 322.3 167 312.1C157.7 303.6 157.7 288.4 167 279L366.1 80H280zM0 120C0 89.07 25.07 64 56 64H168C181.3 64 192 74.75 192 88C192 101.3 181.3 112 168 112H56C51.58 112 48 115.6 48 120V424C48 428.4 51.58 432 56 432H360C364.4 432 368 428.4 368 424V312C368 298.7 378.7 288 392 288C405.3 288 416 298.7 416 312V424C416 454.9 390.9 480 360 480H56C25.07 480 0 454.9 0 424V120z"/></svg>`;

const tooltipOptions = {
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        let label = data.datasets[tooltipItem.datasetIndex].label || '';
        if (label) {
          label += ': ';
        }
        label += intcomma(
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
        );
        const sum = data.datasets[tooltipItem.datasetIndex].data.reduce(
          (total, val) => total + val
        );
        label += ` (${displayPercentage(
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] / sum
        )})`;
        return label;
      },
    },
  },
};

const chartOptions = {
  bar: {
    ...tooltipOptions,
    legend: {
      display: false,
    },
    scales: {
      x: {
        grid: {
          color: gridLinesColor,
        },
      },
      y: {
        grid: {
          color: gridLinesColor,
        },
      },
    },
  },
  pie: { ...tooltipOptions },
  line: {
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          label += intcomma(
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
          );
          return label;
        },
      },
    },
    scales: {
      x: {
        grid: {
          color: gridLinesColor,
        },
      },
      y: {
        grid: {
          color: gridLinesColor,
        },
      },
    },
  },
  stackedBar: {
    indexAxis: 'y',
    scales: {
      x: {
        stacked: false,
        grid: {
          color: gridLinesColor,
        },
        ticks: {
          autoSkip: false,
          fontSize: 10,
          beginAtZero: true,
        },
      },
      y: {
        stacked: true,
        grid: {
          color: gridLinesColor,
        },
      },
    },
    legend: {
      labels: {
        boxWidth: 0,
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          label += intcomma(tooltipItem.yLabel);
          const sum = data.datasets[tooltipItem.datasetIndex].data.reduce(
            (total: number, val: number) => total + val
          );
          label += ` (${displayPercentage(
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] /
              sum
          )})`;
          return label;
        },
      },
    },
  },
};

// from https://iamkate.com/data/12-bit-rainbow/
const colors: string[] = [
  '#817',
  '#a35',
  '#c66',
  '#e94',
  '#ed0',
  '#9d5',
  '#4d8',
  '#2cb',
  '#0bc',
  '#09c',
  '#36b',
  '#639',
].map((color) => {
  if (color.length === 4) {
    return (
      color[0] + color[1] + color[1] + color[2] + color[2] + color[3] + color[3]
    );
  }
  return color;
});

const getPalette = (numberColors: number, opacity?: number): string[] => {
  opacity = typeof opacity === 'undefined' ? 1 : opacity;
  let opacityString = Math.round(opacity * 255).toString(16);
  let choices = colors.slice(0, colors.length);
  if (numberColors < colors.length) {
    const keep = Math.round(colors.length / numberColors);
    choices = choices.filter((_, i) => i % keep === 0);
  }
  let palette: string[] = [];
  while (palette.length < numberColors) {
    palette.push(choices[palette.length % choices.length]);
  }
  return palette
    .slice(0, numberColors)
    .map((c: string) => `${c}${opacityString}`);
};

const wrap = (str: string, limit?: number) => {
  limit = typeof limit === 'undefined' ? 12 : limit;
  const words: string[] = str.split(' ');
  let aux: string[] = [];
  let concat: string[] = [];
  for (let i = 0; i < words.length; i++) {
    concat.push(words[i]);
    let join = concat.join(' ');
    if (join.length > limit) {
      aux.push(join);
      concat = [];
    }
  }
  if (concat.length) {
    aux.push(concat.join(' ').trim());
  }
  return aux;
};

interface APIResponse {
  count: number;
  next: string;
  previous: string;
}

interface StatsAPIResponse extends APIResponse {
  stats: {
    [key: string]:
      | {
          [key: string]: {
            name: string;
            count: number;
          };
        }
      | number;
    total: number;
  };
}

interface DatasetsAPIReponse {
  labels: string[];
  datasets: {
    label: string;
    data: number[] | string[];
  }[];
}

interface ConnectionsSectionEntry {
  date: string;
  date_formatted: string;
  count: number;
  average: number | null;
}

interface ConnectionsAPIResponse extends APIResponse {
  scheduled: ConnectionsSectionEntry[];
  connected: ConnectionsSectionEntry[];
  hotspot: ConnectionsSectionEntry[];
}

interface DemographicsAPIReponse {
  stats: {
    [key: string]: {
      labels: string[];
      connected: number[];
      eligible: number[];
      all: number[];
    };
  };
  year: string;
  model: 'student' | 'household';
}

const chartDataUpdaters = {
  default: (key: string, chart: ChartWithCSVData, data: StatsAPIResponse) => {
    const fullKey = `${key}`;
    key = key.split('-').pop() || '';
    const { stats } = data;
    const chartData = Object.values(
      stats[availableCharts[fullKey].dataKey || key]
    )
      .filter((item) => item.count > 0)
      .map((item) => item.count);
    chart.data.labels = Object.values(
      stats[availableCharts[fullKey].dataKey || key]
    )
      .filter((item) => item.count > 0)
      .map((item) => `${item.name}`);
    chart.data.datasets = [
      {
        data: chartData,
        label: '',
        backgroundColor: getPalette(chartData.length),
        borderWidth: 0,
      },
    ];
    chart.csvData = [[availableCharts[fullKey].label, 'Total']];
    (chart.data.labels as string[]).map((label, i) => {
      (chart.csvData as CSVDataEntries).push([label, chartData[i]]);
    });
    chart.csvData = arrayToCSV(chart.csvData);
    chart.update();
    if ('count' in data && statsCount) {
      statsCount.innerText = intcomma(data.count);
    }
  },
  dailyVsAverage: (
    key: string,
    chart: ChartWithCSVData,
    data: ConnectionsAPIResponse
  ) => {
    key = key.split('-').slice(1).join('-');
    chart.data.labels = (
      Object.values(data[key]) as ConnectionsSectionEntry[]
    ).map((item) => item.date_formatted);
    const chartPalette = getPalette(2);
    chart.data.datasets = [
      {
        data: (Object.values(data[key]) as ConnectionsSectionEntry[]).map(
          (item) => item.count
        ),
        label: 'Daily Total',
        order: 2,
        borderColor: [chartPalette[0]],
      },
      {
        data: (Object.values(data[key]) as ConnectionsSectionEntry[]).map(
          (item) => item.average
        ),
        label: '7 Day Average',
        order: 1,
        borderColor: [chartPalette[1]],
      },
    ];
    chart.csvData = [
      ['Date'].concat(
        chart.data.datasets.map((dataset) => dataset.label) as string[]
      ),
    ];
    chart.data.labels.map((label, i) => {
      (chart.csvData as CSVDataEntries).push(
        [label as string].concat(
          chart.data.datasets.map((dataset) =>
            dataset.data[i] === null
              ? ''
              : (dataset.data[i] as unknown as string)
          )
        )
      );
    });
    chart.csvData = arrayToCSV(chart.csvData);
    chart.update();
  },
  connectedDemographics: (
    key: string,
    chart: ChartWithCSVData,
    data: DemographicsAPIReponse
  ) => {
    const fullKey = `${key}`;
    key = key.split('-').slice(1).join('-');
    const connectedSum = data.stats[key].connected.reduce(
      (total, count) => total + count
    );
    const eligibleSum = data.stats[key].eligible.reduce(
      (total, count) => total + count
    );
    const allSum = data.stats[key].all.reduce((total, count) => total + count);
    chart.data.labels = data.stats[key].labels.map((label, i) => {
      const connectedPercentage = data.stats[key].connected[i] / connectedSum;
      const allPercentage = data.stats[key].all[i] / allSum;
      const performanceDiff = connectedPercentage - allPercentage;
      let prefix = performanceDiff > 0 ? '▲' : performanceDiff < 0 ? '▼' : '';
      if (displayPercentage(Math.abs(performanceDiff)) === '0%') {
        prefix = '';
      }
      if (
        ['connected-by-school', 'connected-by-zip-code'].indexOf(fullKey) !== -1
      ) {
        return [label].concat([
          `${prefix} ${displayPercentage(Math.abs(performanceDiff))}`,
        ]);
      }
      return wrap(label).concat([
        `${prefix} ${displayPercentage(Math.abs(performanceDiff))}`,
      ]);
    });
    chart.data.datasets = [
      {
        data: data.stats[key].connected,
        backgroundColor: getPalette(data.stats[key].connected.length),
        borderWidth: 0,
        label: `Connected`,
      },
      {
        data: data.stats[key].eligible,
        backgroundColor: getPalette(data.stats[key].eligible.length, 0.5),
        borderWidth: 0,
        label: `Eligible`,
      },
      {
        data: data.stats[key].all,
        backgroundColor: getPalette(data.stats[key].all.length, 0.25),
        borderWidth: 0,
        label: 'All Students',
      },
    ];
    if (!chart.options?.plugins) {
      chart.options.plugins = {};
    }
    if (!chart.options?.plugins?.title) {
      chart.options.plugins.title = {
        display: true,
        text: `${intcomma(connectedSum)} Connected Total`,
        font: {
          size: 16,
        },
      };
    }
    chart.options.plugins.subtitle = {
      display: true,
      text: `Last Updated: ${formatters.date(new Date())}`,
      position: 'bottom',
      padding: 10,
    };
    chart.csvData = [
      [availableCharts[fullKey].label.replace(/^By /, '')].concat(
        chart.data.datasets.map((dataset) => dataset.label)
      ),
    ];
    chart.data.labels.map((label, i) => {
      if (Array.isArray(label)) {
        label = `${label[0]}`;
      }
      (chart.csvData as CSVDataEntries).push(
        [label as string].concat(
          chart.data.datasets.map((dataset) =>
            dataset.data[i] === null ? '' : dataset.data[i]
          ) as string[]
        )
      );
    });
    chart.csvData = arrayToCSV(chart.csvData);
    chart.update();
  },
};

const availableCharts = {
  'dashboard-overview': {
    label: 'Opt-in Status',
    type: 'pie',
    options: chartOptions.pie,
  },
  'dashboard-health': {
    label: 'Health',
    type: 'pie',
    options: chartOptions.pie,
  },
  'dashboard-status': {
    label: 'Status',
    type: 'bar',
    options: chartOptions.bar,
  },
  'dashboard-outcome': {
    label: 'Outcome',
    type: 'bar',
    options: chartOptions.bar,
  },
  'epb-serviceable-household': {
    label: 'EPB Serviceable Households',
    type: 'bar',
    options: chartOptions.bar,
    dataKey: 'epb-serviceable',
  },
  'epb-serviceable-student': {
    label: 'EPB Serviceable Students',
    type: 'bar',
    options: chartOptions.bar,
    dataKey: 'epb-serviceable',
  },
  'vec-serviceable-household': {
    label: 'VEC Serviceable Households',
    type: 'bar',
    options: chartOptions.bar,
    dataKey: 'vec-serviceable',
  },
  'vec-serviceable-student': {
    label: 'VEC Serviceable Students',
    type: 'bar',
    options: chartOptions.bar,
    dataKey: 'vec-serviceable',
  },
  'connections-scheduled': {
    label: 'EPB Scheduled',
    type: 'line',
    options: chartOptions.line,
    update: chartDataUpdaters.dailyVsAverage,
  },
  'connections-connected': {
    label: 'EPB Connected',
    type: 'line',
    options: chartOptions.line,
    update: chartDataUpdaters.dailyVsAverage,
  },
  'connections-hotspot': {
    label: 'Hotspots Delivered',
    type: 'line',
    options: chartOptions.line,
    update: chartDataUpdaters.dailyVsAverage,
  },
  'connected-by-district': {
    label: 'By District',
    type: 'bar',
    options: chartOptions.stackedBar,
    update: chartDataUpdaters.connectedDemographics,
  },
  'connected-by-learning-community': {
    label: 'By Learning Community',
    type: 'bar',
    options: chartOptions.stackedBar,
    update: chartDataUpdaters.connectedDemographics,
  },
  'connected-by-municipality': {
    label: 'By Municipality',
    type: 'bar',
    options: chartOptions.stackedBar,
    update: chartDataUpdaters.connectedDemographics,
  },
  'connected-by-council-district': {
    label: 'By Chattanooga Council District',
    type: 'bar',
    options: chartOptions.stackedBar,
    update: chartDataUpdaters.connectedDemographics,
  },
  'connected-by-language': {
    label: 'By Primary Household Language',
    type: 'bar',
    options: chartOptions.stackedBar,
    update: chartDataUpdaters.connectedDemographics,
  },
  'connected-by-grade': {
    label: 'By Grade Level',
    type: 'bar',
    options: chartOptions.stackedBar,
    update: chartDataUpdaters.connectedDemographics,
  },
  'connected-by-gender': {
    label: 'By Gender',
    type: 'bar',
    options: chartOptions.stackedBar,
    update: chartDataUpdaters.connectedDemographics,
  },
  'connected-by-ethnicity': {
    label: 'By Ethnicity',
    type: 'bar',
    options: chartOptions.stackedBar,
    update: chartDataUpdaters.connectedDemographics,
  },
  'connected-by-lunch-status': {
    label: 'By Lunch Status',
    type: 'bar',
    options: chartOptions.stackedBar,
    update: chartDataUpdaters.connectedDemographics,
  },
  'connected-by-cep': {
    label: 'By CEP Status',
    type: 'bar',
    options: chartOptions.stackedBar,
    update: chartDataUpdaters.connectedDemographics,
  },
  'connected-by-school': {
    label: 'By School',
    type: 'bar',
    options: chartOptions.stackedBar,
    update: chartDataUpdaters.connectedDemographics,
  },
  'connected-by-postal-code': {
    label: 'By ZIP Code',
    type: 'bar',
    options: chartOptions.stackedBar,
    update: chartDataUpdaters.connectedDemographics,
  },
};

const intcomma = (input: string | number): string => {
  const value = input.toString().split('.');
  const int = value[0];
  const parts = [int.replace(/\B(?=(\d{3})+(?!\d))/g, ',')];
  if (value.length > 1) {
    parts.push(value.slice(1).join('.'));
  }
  return parts.join('.');
};

const arrayToCSV = (array: CSVDataEntry[]): string => {
  return `data:text/csv;charset=utf-8,${encodeURIComponent(
    array
      .map((row) =>
        row.map((cell) => `"${String(cell).replace(/"/g, '\\"')}"`).join(',')
      )
      .join('\n')
  )}`;
};

const displayPercentage = (value: number): string => {
  if (isNaN(value)) {
    return '';
  }
  return `${(value * 100).toFixed(2).replace(/0+$/, '').replace(/\.$/, '')}%`;
};

export interface ParamsObject {
  [key: string]: string | string[];
}
const queryStringToObject = (
  str: string = document.location.search
): ParamsObject => {
  str = str.replace(/(^\?)/, '');
  if (!str) {
    return {};
  } else if (queryStringToObject.cache[str]) {
    return queryStringToObject.cache[str];
  }
  const _params = new URLSearchParams(str);
  const obj: ParamsObject = {};
  [..._params.entries()].map((entry) => {
    const [key, value] = entry;
    if (Object.keys(obj).includes(key) && !Array.isArray(obj[key])) {
      // if there's already a property here and the value isn't an array, make it one
      obj[key] = [obj[key] as string] as string[];
    }
    if (Array.isArray(obj[key])) {
      (obj[key] as string[]).push(value);
    } else {
      obj[key] = value as string;
    }
  });
  return obj;
};
queryStringToObject.cache = {} as { [key: string]: any };

const objectToQueryString = (obj: ParamsObject): string => {
  return Object.keys(obj)
    .map((key) => {
      if (Array.isArray(obj[key])) {
        return (obj[key] as string[])
          .map((value) => `${key}=${value}`)
          .join('&');
      } else {
        return `${key}=${obj[key]}`;
      }
    })
    .join('&');
};

let statsCount: HTMLSpanElement | null = null;

(
  [
    ...document.querySelectorAll('.stats-container[data-charts]'),
  ] as HTMLDivElement[]
).map((statsContainer) => {
  let updateTimeout: ReturnType<typeof setTimeout> | null = null;

  if (statsContainer.dataset.objectLabel) {
    const statsCountWrapper = elem('p');
    statsCountWrapper.classList.add('text--right', 'mbe--0', 'mi--0');
    statsCount = elem('span') as HTMLSpanElement;
    statsCountWrapper.append(statsCount);
    statsCountWrapper.append(
      document.createTextNode(` ${statsContainer.dataset.objectLabel}s`)
    );
    try {
      statsContainer.querySelector('header')?.append(statsCountWrapper);
    } catch (err) {}
  }

  const displayedCharts = statsContainer.dataset.charts
    ?.split(',')
    .map((name) => name.trim())
    .filter(
      (chartName) => Object.keys(availableCharts).indexOf(chartName) > -1
    );

  if (!displayedCharts?.length) {
    return;
  }

  class ChartContainerChart extends HTMLCanvasElement {
    downloadPNGButton?: HTMLAnchorElement;
    downloadCSVButton?: HTMLAnchorElement;
    sheetsForm?: HTMLFormElement;
    csvData?: CSVDataEntries | string;
  }

  class ChartContainer extends HTMLElement {
    chart: ChartContainerChart;
    caption: HTMLElement;
  }

  const createChartContainer = (id: string, label: string): ChartContainer => {
    const container = elem('figure') as ChartContainer;
    const caption = elem('figcaption');
    const chart = elem('canvas') as HTMLCanvasElement;
    const responsiveChartWrapper = elem('div') as HTMLDivElement;
    responsiveChartWrapper.classList.add('chart-wrapper');
    container.id = id;
    container.classList.add('chart-container', `chart-container--${id}`);
    caption.classList.add('chart-caption');
    // caption.innerText = label;
    container.append(caption);
    responsiveChartWrapper.append(chart);
    container.append(responsiveChartWrapper);
    container.chart = chart;
    container.caption = caption;
    statsContainer.append(container);
    const downloadPNGButton = elem('a') as HTMLAnchorElement;
    downloadPNGButton.download = `edconnect-${id}-${new Date()
      .toISOString()
      .split('T')
      .shift()}.png`;
    downloadPNGButton.href = '#';
    downloadPNGButton.classList.add('download-btn', 'download-btn--png');
    downloadPNGButton.title = 'Download chart as PNG image';
    downloadPNGButton.innerHTML = `PNG ${downloadIcon}`;
    const downloadCSVButton = elem('a') as HTMLAnchorElement;
    downloadCSVButton.download = `edconnect-${id}-${new Date()
      .toISOString()
      .split('T')
      .shift()}.csv`;
    downloadCSVButton.href = '#';
    downloadCSVButton.classList.add('download-btn', 'download-btn--csv');
    downloadCSVButton.title = 'Download chart as CSV spreadsheet';
    downloadCSVButton.innerHTML = `CSV ${downloadIcon}`;
    const csvToSheetsForm = mnml.html`<form method="POST" action="${
      document.body.dataset.csvToSheetsEndpoint
    }" target="_blank" class="csv-to-sheets-form">
      <input type="hidden" name="csv" value="">
      <input type="hidden" name="title" value="EdConnect: ${label} (${
      new Date().toISOString().split('T')[0]
    })">
      <input type="hidden" name="csrfmiddlewaretoken" value="${
        document.body.dataset.csrf
      }">
      <button class="download-btn download-btn--sheets" title="Upload chart as CSV spreadsheet to Google Sheets">Sheets ${sheetsIcon}</button>
    </form>`;
    const downloadButtons = elem('div');
    downloadButtons.classList.add('download-buttons');
    downloadButtons.append(downloadPNGButton);
    downloadButtons.append(downloadCSVButton);
    downloadButtons.append(csvToSheetsForm);
    caption.append(downloadButtons);
    container.chart.downloadPNGButton = downloadPNGButton;
    container.chart.downloadCSVButton = downloadCSVButton;
    container.chart.sheetsForm = caption.querySelector(
      'form'
    ) as HTMLFormElement;
    return container;
  };

  displayedCharts.map((slug: string) => {
    const config = availableCharts[slug];
    const options = Object.assign({}, config.options, { plugins: {} });
    options.plugins.title = {
      display: true,
      text: config.label,
      align: 'center',
      position: 'top',
      font: {
        size: 16,
      },
    };
    options.plugins.subtitle = {
      display: true,
      text: `Last Updated: ${formatters.date(new Date())}`,
      position: 'bottom',
      padding: 10,
    };
    charts[slug] = new Chart(createChartContainer(slug, config.label).chart, {
      type: config.type,
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        ...options,
        ...{
          responsive: true,
          maintainAspectRatio: false,
        },
        ...{
          animation: {
            onComplete: (context) => {
              (
                context.chart.canvas as ChartContainerChart
              ).downloadPNGButton!.href = context.chart.toBase64Image();
              const hasCSVData =
                (context.chart as ChartWithCSVData).csvData &&
                (context.chart as ChartWithCSVData).csvData.length;
              if (hasCSVData) {
                (
                  context.chart.canvas as ChartContainerChart
                ).downloadCSVButton!.classList.remove('invisible');
                (
                  context.chart.canvas as ChartContainerChart
                ).downloadCSVButton!.href = (context.chart as ChartWithCSVData)
                  .csvData as string;
                ((
                  context.chart.canvas as ChartContainerChart
                ).sheetsForm!.querySelector(
                  'input[name="csv"]'
                ) as HTMLInputElement)!.value = decodeURIComponent(
                  (context.chart as ChartWithCSVData).csvData as string
                ).split('data:text/csv;charset=utf-8,')[1];
              } else {
                (
                  context.chart.canvas as ChartContainerChart
                ).downloadCSVButton!.classList.add('invisible');
              }
            },
          },
        },
      },
    });
  });

  const updateStats = () => {
    if (updateTimeout) clearTimeout(updateTimeout);
    showLoader();
    let apiUrl = statsContainer.dataset.api as string;
    const params = {
      ...queryStringToObject(window.location.search),
      ...queryStringToObject(apiUrl.split('?')[1] || ''),
    };
    apiUrl = `${apiUrl.split('?').shift()}?${objectToQueryString(params)}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        Object.keys(charts).map((key) => {
          const chart = charts[key];
          (availableCharts[key].update || chartDataUpdaters.default)(
            key,
            chart,
            data
          );
        });
      })
      .catch(console.error)
      .finally(() => {
        hideLoader();
        updateTimeout = setTimeout(updateStats, 15 * 60 * 1000);
      });
  };

  if (statsContainer) {
    updateStats();
    window.addEventListener('popstate', (ev) => {
      updateStats();
    });
  }

  window.addEventListener('edconnect:filters-updated', (ev) => {
    updateStats();
  });
});

window.addEventListener('input', (ev) => {
  if ((ev.target as HTMLInputElement)?.matches('.filter-option')) {
    const target = ev.target as HTMLInputElement | HTMLSelectElement;
    if (
      document.querySelectorAll(`input[name="${target.name}"]`).length ===
      document.querySelectorAll(`input[name="${target.name}"]:checked`).length
    ) {
      (
        [
          ...document.querySelectorAll(`input[name="${target.name}"]`),
        ] as HTMLInputElement[]
      ).forEach((el) => (el.checked = false));
    }
    const queryString = (
      [
        ...document.querySelectorAll('.filter-option:checked'),
      ] as HTMLInputElement[]
    )
      .map((el) =>
        el.value === '' ? '' : `${el.name}=${encodeURIComponent(el.value)}`
      )
      .concat(
        (
          [
            ...document.querySelectorAll('.filter-option:is(select)'),
          ] as HTMLSelectElement[]
        ).map((el) =>
          el.value === '' ? '' : `${el.name}=${encodeURIComponent(el.value)}`
        )
      );

    const trimmedQueryString = queryString
      ? `?${queryString
          .join('&')
          .replace('&&', '&')
          .replace(/&$/, '')}`.replace(/^\?$/, '')
      : '';
    window.history.pushState(
      queryString,
      document.title,
      trimmedQueryString || '?'
    );
    ([...document.querySelectorAll('.tabs .tab')] as HTMLAnchorElement[]).map(
      (link) => {
        link.href = `${link.dataset.baseUrl}${trimmedQueryString}`;
      }
    );
    target.dispatchEvent(
      new Event('edconnect:filters-updated', { bubbles: true })
    );
  }
});

const tableColumnLabels = {
  'connections-by-school': 'School',
};

(
  [
    ...document.querySelectorAll('.stats-container[data-tables]'),
  ] as HTMLDivElement[]
).map((statsContainer) => {
  const createTable = (tableId: string, columnHeaders: string[]) => {
    const label = {
      'connections-by-school': 'Connections by School',
      overview: 'Overview',
    }[tableId];
    let table = document.getElementById(`stats-table--${tableId}`) as
      | HTMLTableElement
      | undefined;
    if (!table) {
      table = elem('table') as HTMLTableElement;
      table.classList.add('table', 'table--striped');
      table.id = `stats-table--${tableId}`;
      const actions = elem('div') as HTMLDivElement;
      actions.setAttribute('data-table-id', table.id);
      actions.classList.add('table__actions');
      const downloadCSVButton = elem('a') as HTMLAnchorElement;
      downloadCSVButton.download = `edconnect-${tableId}-${new Date()
        .toISOString()
        .split('T')
        .shift()}.csv`;
      downloadCSVButton.href = '#';
      downloadCSVButton.classList.add('download-btn', 'download-btn--csv');
      downloadCSVButton.title = 'Download table as CSV spreadsheet';
      downloadCSVButton.innerHTML = `CSV ${downloadIcon}`;
      const csvToSheetsForm = mnml.html`<form method="POST" action="${
        document.body.dataset.csvToSheetsEndpoint
      }" target="_blank" class="csv-to-sheets-form">
        <input type="hidden" name="csv" value="">
        <input type="hidden" name="title" value="EdConnect: ${label} (${
        new Date().toISOString().split('T')[0]
      })">
        <input type="hidden" name="csrfmiddlewaretoken" value="${
          document.body.dataset.csrf
        }">
        <button class="download-btn download-btn--sheets" title="Upload chart as CSV spreadsheet to Google Sheets">Sheets ${sheetsIcon}</button>
      </form>`;
      const downloadButtons = elem('div') as HTMLDivElement;
      downloadButtons.classList.add('download-buttons');
      downloadButtons.append(elem('span'));
      downloadButtons.append(downloadCSVButton);
      downloadButtons.append(csvToSheetsForm);
      actions.append(downloadButtons);
      const tableWrapper = elem('div') as HTMLDivElement;
      tableWrapper.classList.add('table__wrapper');
      tableWrapper.append(table);
      statsContainer.append(actions);
      statsContainer.append(tableWrapper);
    } else {
      table.innerHTML = '';
    }
    const thead = elem('thead') as HTMLTableSectionElement;
    const tbody = elem('tbody') as HTMLTableSectionElement;
    table.append(thead);
    thead.append(elem('tr'));
    columnHeaders.map((header) => {
      const cell = elem('th') as HTMLTableCellElement;
      cell.scope = 'col';
      cell.innerText = header;
      thead.firstChild?.appendChild(cell);
    });
    table.append(tbody);
    const tfoot = elem('tfoot');
    tfoot.append(elem('tr'));
    table.append(tfoot);
    return table;
  };

  statsContainer.dataset.tables?.split(',').map((tableId) => {
    tableId = tableId.trim();
    let updateTimeout: ReturnType<typeof setTimeout> | null = null;

    const updateStats = () => {
      if (updateTimeout) clearTimeout(updateTimeout);
      showLoader();
      let apiUrl = statsContainer.dataset.api as string;
      const params = {
        ...queryStringToObject(window.location.search),
        ...queryStringToObject(apiUrl.split('?')[1] || ''),
      };
      apiUrl = `${apiUrl.split('?').shift()}?${objectToQueryString(params)}`;
      fetch(apiUrl)
        .then((results) => results.json())
        .then((results: DatasetsAPIReponse) => {
          const table = createTable(tableId, [
            tableColumnLabels[tableId] || '',
            ...results.labels,
          ]);
          const tbody = table.querySelector('tbody') as HTMLTableSectionElement;
          tbody.innerHTML = '';
          results.datasets.map((entry, i: number) => {
            const row = elem('tr') as HTMLTableRowElement;
            const rowHeader = elem('th') as HTMLTableCellElement;
            rowHeader.scope = 'row';
            rowHeader.innerText = entry.label;
            rowHeader.classList.add('text--left');
            row.append(rowHeader);
            entry.data.map((count) => {
              const cell = elem('td');
              cell.classList.add('text--right', 'text--nums');
              cell.innerText = intcomma(count);
              row.append(cell);
            });
            tbody.append(row);
          });
          const csvData = [...table.querySelectorAll('tr')].map((tr) => {
            return (
              [...tr.querySelectorAll('td, th')] as HTMLTableCellElement[]
            ).map((cell) => cell.innerText);
          });
          const actions = document.querySelector(
            `.table__actions[data-table-id="${table.id}"]`
          ) as HTMLDivElement | null;
          if (actions) {
            (actions.querySelector(
              '.download-btn--csv'
            ) as HTMLAnchorElement)!.href = arrayToCSV(csvData);
            const sheetsInput = actions.querySelector(
              'input[name="csv"]'
            ) as HTMLInputElement;
            sheetsInput.value = decodeURIComponent(
              arrayToCSV(csvData).split('data:text/csv;charset=utf-8,')[1]
            );
          }
          const showTotals = JSON.parse(
            statsContainer.dataset.totals || 'true'
          );
          if (showTotals) {
            const totalRow = table.querySelector(
              'tfoot tr'
            ) as HTMLTableRowElement;
            totalRow.innerHTML = '';
            const totalLabel = elem('th') as HTMLTableCellElement;
            totalLabel.scope = 'row';
            totalLabel.innerText = 'Total';
            totalLabel.classList.add('text--left');
            totalRow.append(totalLabel);
            (results.datasets[0]?.data as number[]).map(
              (count: number, i: number) => {
                const cell = elem('td') as HTMLTableCellElement;
                cell.classList.add('text--right', 'text--nums');
                cell.innerText = intcomma(
                  results.datasets
                    .map((dataset) => dataset.data[i] as unknown as number)
                    .reduce((a, b) => a + b)
                );
                totalRow.append(cell);
              }
            );
          }
          hideLoader();
        })
        .catch(console.error);
    };

    updateStats();

    window.addEventListener('popstate', (ev) => {
      updateStats();
    });

    window.addEventListener('edconnect:filters-updated', () => {
      updateStats();
    });
  });
});

mnml.listen(
  'submit',
  '[data-sheets-enabled="false"] .csv-to-sheets-form',
  (ev, form) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (
      confirm(
        'You will need to connect your Google Account to send this to Sheets. Continue?'
      )
    ) {
      window.location.href = `${
        document.body.dataset.sheetsLogin
      }&next=${encodeURIComponent(window.location.href)}`;
    }
    return false;
  }
);

mnml.listen('change', '.auto-filter-form', (ev, form) => {
  const params = queryStringToObject(window.location.search);
  const formData = new FormData(form as HTMLFormElement);
  formData.forEach((value, key) => {
    params[key] = value as string;
  });
  showLoader();
  window.location.href = `${window.location.pathname}?${objectToQueryString(
    params
  )}`;
});
